import React, {useState, Suspense} from "react";
import {useSelector} from "react-redux";
import { Route, Routes } from "react-router-dom";
import useEffectLocation from "../hooks/useEffectLocation";

import './style.scss';

const MainDemo = React.lazy(() => import("../views/home/MainDemo"));

const Work = React.lazy(() => import("../views/Work"));
const ProjectDetails = React.lazy(() => import("../views/portfolio/ProjectDetails"));

const About = React.lazy(() => import("../views/About"));
const Contact = React.lazy(() => import("../views/Contact"));
const BlogDetails = React.lazy(() => import("../views/blog/BlogDetails"));

const Products = React.lazy(() => import("../views/Products"));
const SubProducts = React.lazy(() => import("../views/SubProducts"));
const ProductDetail = React.lazy(() => import("../views/ProductDetail"));
const Catalogue = React.lazy(() => import("../views/Catalogue"));
const Exports = React.lazy(() => import("../views/Exports"));
const Country = React.lazy(() => import("../views/Country"));
const Calculator = React.lazy(() => import("../views/Calculator"));
const Packing = React.lazy(() => import("../views/Packing"));
const Technical = React.lazy(() => import("../views/Technical"));
const News = React.lazy(() => import("../views/News"));
const NotFound = React.lazy(() => import("../views/NotFound"));

const Router = () => {

    const [transPage, setTransPage] = useState("in");
    const scrollbar = useSelector(state => state.scrollbar);

    const location = useEffectLocation((l) => {
        setTransPage("out");
    })

    const onAnimateEnd = () => {
        if (transPage !== "out") return;
        scrollbar.current?.scrollTo(0, 0, 0);
        window.scrollTo({left: 0, top: 0});
        setTransPage("in");
    }


    return (

        <div id="dsn-content" className={`dsn-transition-page dsn-animate-${transPage}`}
             onAnimationEnd={onAnimateEnd}
        >
            <Suspense fallback={<div className="background-main h-100-v" />}>
                <Routes location={location}>

                    <Route path="/" element={<MainDemo/>}/>
                    <Route exact path="/about" element={<About/>}/>
                    <Route exact path="/contact" element={<Contact/>}/>
                    <Route exact path="/products/:slug" element={<Products/>}/>
                    <Route exact path="/products/:slug/:sslug/collections" element={<SubProducts/>}/>
                    <Route exact path="/products/:slug/:pslug" element={<ProductDetail/>}/>
                    <Route exact path="/technical-specification" element={<Technical/>}/>
                    <Route exact path="/packing-details" element={<Packing/>}/>
                    <Route exact path="/tiles-calculator" element={<Calculator/>}/>
                    <Route exact path="/catalogue" element={<Catalogue/>}/>
                    <Route exact path="/exports" element={<Exports/>}/>
                    <Route exact path="/exports/:slug" element={<Country/>}/>
                    <Route exact path="/latest-news" element={<News/>}/>
                    <Route exact path="/news/:slug" element={<BlogDetails/>}/>

                    <Route exact path="/portfolio" element={<Work/>}/>
                    <Route exact path="/portfolio-2" element={<BlogDetails/>}/>
                    <Route exact path="/portfolio/:slug" element={<ProjectDetails/>}/>

                    <Route exact path="*" element={<NotFound />}/>
                </Routes>
            </Suspense>
        </div>


    );
}

export default React.memo(Router);